import React from "react"
import CommunitySVG from "../assets/svgs/community.svg"
import ConferenceSVG from "../assets/svgs/conference.svg"
import EventsSVG from "../assets/svgs/events.svg"
import MobileMarketingSVG from "../assets/svgs/mobilemarketing.svg"
import SocialInfluencerSVG from "../assets/svgs/socialinfluencer.svg"

export default function getServiceSVG(name) {
  switch (name) {
    case "community":
      return <CommunitySVG />
    case "conference":
      return <ConferenceSVG />
    case "events":
      return <EventsSVG />
    case "mobile-marketing":
      return <MobileMarketingSVG />
    case "social-influencer":
      return <SocialInfluencerSVG />
    default:
      throw new Error(`${name} svg not found`)
  }
}
