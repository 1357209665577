import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import getServiceIcon from "../utils/getserviceicon"
import "../styles/services.scss"

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          services {
            title
            icon
            body
          }
        }
      }
    }
  `)
  const { services } = data.site.siteMetadata

  return (
    <Layout>
      <SEO
        title="Services"
        description="We have a wide variety of services to help you achieve your business needs. Contact us to discuss further."
      />

      <div className="services-container">
        <div className="services__heading">
          <h1>Services</h1>
          <p>Contact us to get a free quote</p>
          <Link to="/contact">
            <FontAwesomeIcon icon="envelope" />
          </Link>
        </div>
        <div className="services__content">
          {services.map(({ title, icon, body }) => (
            <div className="service" key={title}>
              <div>
                <h3>{title}</h3>
                <hr />
                <p>{body}</p>
              </div>
              <div>{getServiceIcon(icon)}</div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
